<template>
  <div class="shop-forget">
    <div class="main-card">
      <div class="cell" style="padding: 40px" v-if="seep == 1">
        <div class="title">查找您的账户</div>
        <div class="ipt">
          <el-input placeholder="请输入内容" v-model.trim="acc">
            <template slot="prepend">
              <i class="el-icon-search"></i>
            </template>
          </el-input>
        </div>
        <div>
          <el-button
            @click="next(1)"
            :disabled="acc == ''"
            type="primary"
            style="min-width: 380px"
            >查找</el-button
          >
        </div>
      </div>
      <div class="cell" v-if="seep == 2">
        <div class="top">
          <div class="fk">{{ acc[0] }}</div>
          <span>{{ acc }}</span>
        </div>
        <div class="title">请验证您自己以继续</div>
        <div style="padding: 0 40px 40px">
          <div class="phone">
            <div class="yuan"></div>
            <div>
              <div style="margin-bottom: 4px">发送代码至电话</div>
              <div>+86 {{ phoneTxt }}</div>
            </div>
          </div>
        </div>
        <div>
          <el-button type="primary" style="min-width: 380px" @click="next(2)"
            >继续</el-button
          >
        </div>
      </div>
      <div class="cell" v-if="seep == 3">
        <div class="top" style="font-size: 18px">
          <i class="el-icon-arrow-left"></i>
          <span>返回</span>
        </div>
        <div class="title">输入验证码</div>
        <div style="margin-top: 20px; color: #999; font-size: 14px">
          请输入已发送至您注册手机
          <span style="color: #000">+86 {{ phoneTxt }}</span>
          的6位数的验证码
        </div>
        <div>
          <vue-sms-check-code
          v-model="smsCode"
            title=" "
            :code-num="6"
            :is-error="isError"
            error-color="#ee4d2d"
            @finish="getSmsCodes"
            style="width: 300px; font-size: 16px"
          />
        </div>
        <div style="text-align: center">
          <div style="font-size: 14px; color: green; margin-bottom: 10px">
            验证码：{{ smsCodeText }}
          </div>
          <div style="margin-bottom: 20px">
            <el-button type="primary" style="min-width: 320px" @click="next(3)"
              >验证</el-button
            >
          </div>
          <div style="color: #b0b0b0; font-size: 14px">
            <span v-if="num != 60">请稍等{{ num }}秒后重新发送</span>
            <span
              v-else
              style="
                color: #ee4d2d;
                text-decoration: underline;
                cursor: pointer;
              "
              @click="getSmsCodeF"
              >重新发送</span
            >
          </div>
        </div>
      </div>
      <div class="cell" v-if="seep == 4">
        <div class="top">
          <div class="fk">{{ acc[0] }}</div>
          <span>{{ acc }}</span>
        </div>
        <div class="title">设置新密码</div>
        <div
          style="
            margin-top: 20px;
            color: #999;
            font-size: 14px;
            padding: 0 30px;
          "
        >
          密码长度为8-16个字符，必须包含大小写字母/数字/符号任意两者组合。
        </div>
        <div style="padding: 40px 80px 40px">
          <div style="margin-bottom: 20px">
            <el-input
              show-password
              v-model="pwd"
              placeholder="输入新密码"
            ></el-input>
          </div>
          <div>
            <el-input
              show-password
              v-model="npwd"
              placeholder="确认新密码"
            ></el-input>
          </div>
        </div>
        <div>
          <el-button
            :disabled="pwd == '' || npwd == '' || pwd != npwd"
            type="primary"
            style="min-width: 380px"
            @click="next(4)"
            >储存</el-button
          >
        </div>
      </div>
      <div class="cell" style="padding-top: 30px" v-if="seep == 5">
        <div>
          <i
            style="font-size: 60px; color: #83e2a4"
            class="el-icon-success"
          ></i>
        </div>
        <div style="font-size: 18px; margin: 10px 0">密码储存成功</div>
        <div style="font-size: 14px; color: #aeaeae">{{ djs }}秒后自动返回</div>
      </div>
    </div>

    <el-dialog
      title="验证"
      :visible.sync="dialogVisible"
      width="350px"
      center
      :close-on-click-modal="false"
      style="padding-top: 100px"
    >
      <slide-verify
        :l="42"
        :r="10"
        :w="310"
        :h="155"
        :show="false"
        @success="onSuccess"
        @fail="onFail"
        ref="slideblock"
        :slider-text="text"
      ></slide-verify>
      <div
        @click="onRefresh"
        style="
          text-align: center;
          margin-top: 20px;
          color: #0055aa;
          cursor: pointer;
          font-size: 12px;
        "
      >
        点击以刷新图片
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { findAccount, getSmsCode } from "@/api/common.js";
import { forgotPassword } from "@/api/shop.js";
export default {
  data() {
    return {
      acc: "",
      seep: 1,
      radio: "",
      phone: "",
      phoneTxt: "",
      dialogVisible: false,
      text: "滑动横条以完成拼图验证",
      smsCodeTxt: "获取中",
      num: 59,
      isError: false,
      pwd: "",
      npwd: "",
      time: null,
      time2: null,
      djs: 5,
      smsCode: "",
    };
  },
  methods: {
    onSuccess() {
      console.log("成功");
      let _this = this;
      this.imgRZ = true;
      setTimeout(function () {
        _this.dialogVisible = false;
        _this.seep = 3;
        _this.getSmsCodeF();
      }, 1000);
    },
    onFail() {
      console.log("失败");
    },
    onRefresh() {
      console.log("刷新了");
      this.$refs.slideblock.reset();
    },
    next(num) {
      if (num == 1) {
        findAccount({
          account: this.acc,
          type: 2,
        }).then((res) => {
          if (res.code == 1) {
            this.phone = res.data.mobile;
            this.phoneTxt =
              res.data.mobile.substring(0, 3) +
              "****" +
              res.data.mobile.substring(7);
            this.seep = 2;
          }

        });
      }
      if (num == 2) {
        this.dialogVisible = true;
        // this.seep = 3;
      }
      if (num == 3) {
        if (this.smsCode) {
          this.seep = 4;
        } else {
          this.$message.error("请输入验证码");
        }
      }
      if (num == 4) {
        forgotPassword({
          account: this.acc,
          new_pwd: this.pwd,
          confirm_pwd: this.npwd,
        }).then((res) => {
          if (res.code == 1) {
            this.seep = 5;
            let _this = this;
            this.time2 = setInterval(function () {
              _this.djs--;
              if (_this.djs == 0) {
                clearInterval(_this.time2);
                _this.$router.go(-1);
              }
            }, 1000);
          }
        });
      }
    },
    getSmsCodeF() {
      let _this = this;
      this.smsCodeText = "获取中···";
      getSmsCode({
        mobile: this.phone,
      }).then((res) => {
        if (res.code == 1) {
          this.time = setInterval(function () {
            _this.num--;
            _this.smsCodeText = res.data.code;
            if (_this.num == 0) {
              clearInterval(_this.time);
              _this.num = 60;
            }
          }, 1000);
        }
      });
    },
    getSmsCodes(value) {
      let _this = this;
      this.isError = false;

      if (value !== this.smsCodeText) {
        setTimeout(() => {
          _this.isError = true;
          _this.$message.error("验证码错误");
        }, 500);
      } else {
        this.smsCode = this.smsCodeText;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
    clearInterval(this.time2);
  },
};
</script>
<style lang="less" scoped>
.shop-forget {
  .cell {
    width: 480px;
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
    margin: 160px auto 0;
    // padding:0 40px 40px;
    padding-bottom: 40px;
    text-align: center;
    .top {
      width: 100%;
      text-align: left;
      background: #fbfbfb;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .fk {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #04ceb2;
        text-align: center;
        line-height: 30px;
        color: #fff;
        font-size: 16px;
        margin-right: 10px;
      }
    }
    .title {
      font-size: 24px;
    }
    .ipt {
      margin: 30px 10px 30px;
    }
    .phone {
      width: 100%;
      padding: 20px;
      background: #f5f5f5;
      margin: 30px 0 0;
      display: flex;
      align-items: center;
      text-align: left;
      font-size: 14px;
      .yuan {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        padding: 4px;
        border: 4px solid #ee4d2d;
        background: #fff;
        margin-right: 20px;
      }
    }
  }
}
</style>